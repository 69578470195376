import aboutMeImg from "../images/me_solo_ballroom.png";
import { NavLink } from "react-router-dom";
import { FiArrowUpRight, FiArrowDownRight } from "react-icons/fi";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const AboutMe = ({ name }) => {
  const [ref, inView] = useInView({
    threshold: 0.4,
    triggerOnce: true,
  });

  const staggerVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const paragraphVariants = {
    initial: { y: 20, opacity: 0 },
    animate: { y: 0, opacity: 1 },
  };

  return (
    <section className="about">
      <div className="contentContainer subButtons">
        <div>
          <NavLink to="/forrowithmatheus">
            <button className="btn" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.99 }}>
              <p>Forró Classes</p>
              <div>
                <FiArrowUpRight whileHover={{ scale: 4 }} className="arrow-icon" />
              </div>
            </button>
          </NavLink>
        </div>
        <div>
          <NavLink to="/contact">
            <button className="btn" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.99 }}>
              <p>Book room</p>
              <div>
                <FiArrowUpRight whileHover={{ scale: 4 }} className="arrow-icon" />
              </div>
            </button>
          </NavLink>
        </div>
        <div>
          <a href="#moreinfo">
            <button className="btn" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.99 }}>
              <p>More info</p>
              <div>
                <FiArrowDownRight whileHover={{ scale: 4 }} className="arrow-icon" />
              </div>
            </button>
          </a>
        </div>
      </div>
      <div className="container aboutContainer">
        <div className="row">
          <motion.div
            className="personalImage col-12 col-lg-4"
            ref={ref}
            initial={{ x: "-10vw", opacity: 0, scale: 0.5 }}
            animate={inView ? { x: 0, opacity: 1, scale: 0.9 } : { x: "-10vw", opacity: 0, scale: 0.5 }}
            transition={{ duration: 0.4, ease: "easeInOut" }}
            whileHover={{ scale: 1 }}
          >
            <motion.img src={aboutMeImg} alt={name} />
          </motion.div>
          <div className="personalInfo col-12 col-lg-8">
            <motion.div className="contentContainer" variants={staggerVariants}>
              <motion.h4 variants={paragraphVariants}>Nice to meet you! 👋🏻</motion.h4>
              <motion.h5 variants={paragraphVariants}>I'm Matheus, ready to welcome you in the Balaio!</motion.h5>
              <motion.div
                className="contentDescription"
                variants={staggerVariants}
                initial="initial"
                animate={inView ? "animate" : "initial"}
              >
                <motion.p variants={paragraphVariants}>
                  Balaio was born with the <span style={{ color: "white" }}>mission</span> of hosting high quality classes of different dances, by different teachers, under the same roof.
                </motion.p>
                <br />
                <motion.p variants={paragraphVariants}>
                  We are guided by the <span style={{ color: "white" }}>values</span> of:
                  <ul style={{"list-style-type": "circle", "list-style-position": "inside", "margin-left": "10px"}}>
                    <li> <span style={{ color: "white" }}>Respect:</span> to build a safe space for our dancing</li>
                    <li> <span style={{ color: "white" }}>Empathy:</span> mind the feelings of your fellow dancers</li>
                    <li> <span style={{ color: "white" }}>Fun:</span> relieve stress and recharge your energies</li> 
                  </ul>
                </motion.p>
                <br />
                <motion.p variants={paragraphVariants}>
                  Our <span style={{ color: "white" }}>vision</span> is to transform the lifes of all our members for better, including teachers and students.
                  <ul style={{"list-style-type": "circle", "list-style-position": "inside", "margin-left": "10px"}}>
                    <li> <span style={{ color: "white" }}>Students</span> will become able to go to dancing partys with confidence, 
                    having day by day more and more fun with dance and making more connections in the dance community.</li>
                    <li> <span style={{ color: "white" }}>Teachers</span> will find a perfect place to host their classes: clean, cozy, well-equiped, central;
                    a magnet for new students (some might even come from our very dancing hub).</li>
                  </ul>
                </motion.p>
              </motion.div>
              {/* <NavLink to="/portfolio">
                <motion.button className="btn" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.99 }}>
                  <p>View Portfolio</p>
                  <div>
                    <FiArrowUpRight whileHover={{ scale: 4 }} className="arrow-icon" />
                  </div>
                </motion.button>
              </NavLink> */}
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
