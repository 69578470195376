import { motion } from "framer-motion";

const SocialIcons = () => {
  const styles = {
    icon: {
      textDecoration: "none",
      fontSize: "22px",
      padding: "10px",
      transition: "0.2s ease-in",
    },
  };

  return (
    <div className="socialIcons" style={styles.socialIcons}>
      <a className="icon" style={styles.icon} 
      target="_blank" 
      rel="noreferrer"
      href="https://www.instagram.com/forrowithmatheus/">
        <motion.i
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 1.6, duration: 0.5, type: "spring" }}
          className="fa-brands fa-instagram"
          aria-hidden="true"
          title="Instagram Profile"
        ></motion.i>
      </a>
      {/* <a className="icon" style={styles.icon} target="_blank" href="https://www.facebook.com/forrowithmatheus">
        <motion.i
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 1.5, duration: 0.5, type: "spring" }}
          className="fa-brands fa-facebook"
          aria-hidden="true"
          title="Facebook Profile"
        ></motion.i>
      </a> */}
      <a className="icon" style={styles.icon} 
        target="_blank" 
        rel="noreferrer"
        href="https://open.spotify.com/playlist/2UrQvyRcbuxDuNZniJQ13Y?si=-2AlwgOmRoet_mQ8Hp04LQ"
      >
        <motion.i
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 1.5, duration: 0.5, type: "spring" }}
          className="fa-brands fa-spotify"
          aria-hidden="true"
          title="Spotify Playlist"
        ></motion.i>
      </a>
      <a className="icon" style={styles.icon} href="/contact">
        <motion.i
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 1.5, duration: 0.5, type: "spring" }}
          className="fa fa-envelope"
          aria-hidden="true"
          title="Email"
        ></motion.i>
      </a>
    </div>
  );
};

export default SocialIcons;
