import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi";

const Prices = ({ name }) => {
  const staggerVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const paragraphVariants = {
    initial: { y: 20, opacity: 0 },
    animate: { y: 0, opacity: 1 },
  };

  return (
    <section className="about">
      <div className="aboutContainer2 container contentContainer" style={{"padding":"18px 0"}}>
        <motion.div variants={staggerVariants}>
          <motion.h5 style={{"textAlign": "center"}} variants={paragraphVariants}><u>PRICES</u></motion.h5>
          <div>
            <div className="row contentDescription" style={{"textAlign": "center"}}>
              <div className="tableColumn col-12 col-lg-4" style={{"margin-bottom": "20px"}}>
                <motion.h4 style={{"margin-bottom": "3px"}} variants={paragraphVariants}>Drop-In</motion.h4>
                {/* <p><span style={{ }}>1 Class</span> (+practice): <span style={{ color: "white" }}>15€</span></p> */}
                {/* <p><span style={{ }}>2 Classes</span>  (+practice): <span style={{ color: "white" }}>25€</span></p> */}
                <p>1 Class: <span style={{ color: "white" }}>15€</span></p>
                <p>2 Classes: <span style={{ color: "white" }}>25€</span></p>
                <p>You can join our end-of-class practice!</p>
                <NavLink style={{"display": "flex", "justifyContent": "center"}} target="_blank" to="https://goo.gl/maps/fcFrAt5SEFckZ75z6">
                  <button className="btn" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.99 }}>
                    <p>Find us on the map</p>
                    <div>
                      <FiArrowUpRight whileHover={{ scale: 4 }} className="arrow-icon" />
                    </div>
                  </button>
                </NavLink>
              </div>
              <div className="tableColumn col-12 col-lg-4" style={{"margin-bottom": "20px"}}>
                <motion.h4 style={{"margin-bottom": "3px"}} variants={paragraphVariants}>Season Membership</motion.h4>
                <div style={{"margin-bottom": "10px"}}> 
                  <p style={{ }}>4 Classes/Month (~1/week)</p>
                  <p> 126€/3months</p> 
                  <p style={{ color: "white" }}>(=10,50€/class)</p>
                </div>
                <div> 
                  <p style={{ }}>8 Classes/Month (~2/week) </p>
                  <p> 189€/3months</p> 
                  <p style={{ color: "white" }}>(=7,88€/class)</p>
                </div>
                <NavLink style={{"display": "flex", "justifyContent": "center"}} target="_blank" to="https://docs.google.com/forms/d/e/1FAIpQLSc8Fsas8IZLyLRBRi67pm_H3xPC30YMiMEO7ueYlwKXrJUO7Q/viewform?usp=sf_link">
                    <button className="btn" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.99 }}>
                      <p>Register now</p>
                      <div>
                        <FiArrowUpRight whileHover={{ scale: 4 }} className="arrow-icon" />
                      </div>
                    </button>
                  </NavLink>
              </div>
              <div className="tableColumn col-12 col-lg-4" style={{"margin-bottom": "20px"}}>
                <motion.h4 style={{"margin-bottom": "5px"}} variants={paragraphVariants}>Urban Sports (USC)</motion.h4>
                <p style={{ color: "white" }}> Come get a trial class!</p>
                <p> For long-term participation, get more info at our school =)</p>
                  <NavLink style={{"display": "flex", "justifyContent": "center"}} target="_blank" to="https://urbansportsclub.com/de/venues/find-your-way-to-move">
                  <button className="btn" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.99 }}>
                    <p>Find us on USC</p>
                    <div>
                      <FiArrowUpRight whileHover={{ scale: 4 }} className="arrow-icon" />
                    </div>
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Prices;
