import Hero from "../../components/Hero";
import About from "../../components/AboutMe";
import WhoAreYou from "../../components/WhoAreYou";

const Landing = ({ name }) => {
  const styles = {
    landing: {
      height: (0.6 * window.innerWidth).toString() + "px", //calcular em funcao da largura da tela (conforme imagem)
      maxHeight: "400px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      // backgroundImage: `url(${backImage})`,
      // backgroundSize: "cover",
      // backgroundPosition: "top",
      // backgroundOpacity: "0.3"
    },
  };

  return (
    <>
      <section className="landing container" style={styles.landing}>
        <Hero name={name} />
      </section>
      <section>
        <About />
      </section>
      <section id="moreinfo">
        <WhoAreYou />
      </section>
      
    </>
  );
};

export default Landing;
