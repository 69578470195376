import SocialIcons from "../components/SocialIcons";
const Footer = () => {

  return (
    <footer>
      <SocialIcons />
      <div className="footer-link">
        <p>Matheus Antunes </p>
      </div>
    </footer>
  );
};

export default Footer;
