import { motion } from "framer-motion";

const Timetable = ({ name }) => {
  const staggerVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const paragraphVariants = {
    initial: { y: 20, opacity: 0 },
    animate: { y: 0, opacity: 1 },
  };

  return (
    <section className="about">
      <div className="aboutContainer2 container contentContainer" style={{"padding":"18px 0"}}>
        <motion.div variants={staggerVariants}>
          <motion.h5 style={{"textAlign": "center"}} variants={paragraphVariants}><u>TIMETABLE</u></motion.h5>
          <motion.p style={{"textAlign": "center", "marginBottom": "15px"}} className="contentDescription" variants={paragraphVariants}>
            <p>Currently all our classes take place on</p>
            <b style={{ fontSize:"18px", color: "white" }}>Thursdays</b>
          </motion.p>
          <div>
            <div className="row contentDescription" style={{"textAlign": "center"}}>
              <div className="tableColumn col-12 col-lg-4" style={{"margin-bottom": "10px"}}>
              <motion.h4 style={{"margin-bottom": "0"}} variants={paragraphVariants}>Beginners - 6:45pm</motion.h4>
                {/* <p style={{ color: "white" }}>6:45pm</p>  */}
                From 0 to having fun with all basic moves
              </div>
              <div className="tableColumn col-12 col-lg-4" style={{"margin-bottom": "10px"}}>
                <motion.h4 style={{"margin-bottom": "0"}} variants={paragraphVariants}>Intermediate - 7:45pm</motion.h4>
                {/* <p style={{ color: "white" }}>7:45pm</p>  */}
                Improving repertoire and techniques
              </div>
              <div className="tableColumn col-12 col-lg-4" style={{"margin-bottom": "10px"}}>
                <motion.h4 style={{"margin-bottom": "0"}} variants={paragraphVariants}>Advanced - 8:45pm</motion.h4>
                {/* <p style={{ color: "white" }}>8:45pm</p>  */}
                Advanced concepts and movements
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Timetable;
