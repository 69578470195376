import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi";

const WhoAreYou = ({ name }) => {
  const staggerVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const paragraphVariants = {
    initial: { y: 20, opacity: 0 },
    animate: { y: 0, opacity: 1 },
  };

  return (
    <section className="about">
      <div className="aboutContainer2 container">
      <motion.div className="contentContainer" variants={staggerVariants} style={{"textAlign": "center"}}>
        <motion.h5 variants={paragraphVariants}>Who are you?</motion.h5>
      </motion.div>
        <div className="row">
          {/* Dance Teacher */}
          <div className="personalInfo col-12 col-lg-4" style={{"marginBottom":"20px"}}>
            <motion.div className="contentContainer" variants={staggerVariants}>
              <motion.h4 variants={paragraphVariants}>Dance Teacher</motion.h4>
              <motion.p className="contentDescription" variants={paragraphVariants}  style={{ color: "white" }}>
                I'm searching a beautiful, cozy studio where I can teach my classes.
              </motion.p>
              <NavLink to="/contact">
                <motion.button className="btn" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.99 }}>
                  <p>Tell me more</p>
                  <div>
                    <FiArrowUpRight whileHover={{ scale: 4 }} className="arrow-icon" />
                  </div>
                </motion.button>
              </NavLink>
            </motion.div>
          </div>

          <div className="personalInfo col-12 col-lg-4" style={{"marginBottom":"20px"}}>
            <motion.div className="contentContainer" variants={staggerVariants}>
              <motion.h4 variants={paragraphVariants}>Forró Lover</motion.h4>
              <motion.p variants={paragraphVariants} style={{ color: "white" }}>
                I'm looking for forró classes in Berlin. Meet people, have fun and learn skills.
              </motion.p>
              <NavLink to="/forrowithmatheus">
                <motion.button className="btn" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.99 }}>
                  <p>Join us now!</p>
                  <div>
                    <FiArrowUpRight whileHover={{ scale: 4 }} className="arrow-icon" />
                  </div>
                </motion.button>
              </NavLink>
            </motion.div>
          </div>

          <div className="personalInfo col-12 col-lg-4">
            <motion.div className="contentContainer" variants={staggerVariants}>
              <motion.h4 variants={paragraphVariants}>Explorer</motion.h4>
              <motion.p variants={paragraphVariants} style={{ color: "white" }}>
                I'd like to know what else I can find in the Balaio (dances & teachers).
              </motion.p>
              <NavLink to="/portfolio">
                <motion.button className="btn" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.99 }}>
                  <p>Meet our partners</p>
                  <div>
                    <FiArrowUpRight whileHover={{ scale: 4 }} className="arrow-icon" />
                  </div>
                </motion.button>
              </NavLink>
            </motion.div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default WhoAreYou;
