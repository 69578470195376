import Hero2 from "../../components/Hero2";
import About2 from "../../components/AboutMe2";
import Timetable from "../../components/Timetable";
import Prices from "../../components/Prices";

const ForroWithMatheus = ({ name }) => {
  const styles = {
    landing: {
      height: (0.6 * window.innerWidth).toString() + "px", //calcular em funcao da largura da tela (conforme imagem)
      maxHeight: "400px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    subSection: {
      marginBottom: "25px"
    }

  };

  return (
    <>
      <section className="landing container" style={styles.landing}>
        <Hero2 name={name} />
      </section>
      <About2 />
      <section id="moreinfo" style={styles.subSection}><Timetable /></section>
      <section style={styles.subSection}><Prices /></section>
    </>
  );
};

export default ForroWithMatheus;
