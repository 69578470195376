import Header from "./components/Header";
import Footer from "./components/Footer";

import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import Landing from "./pages/landing/Landing";
import Portfolio from "./pages/portfolio/Portfolio";
import ForroWithMatheus from "./pages/forrowithmatheus/ForroWithMatheus";
import Contact from "./pages/contact/Contact";
import PageNotFound from "./pages/404/PageNotFound";

function App() {
  const personalDetails = {
    name: "Balaio",
    location: "Berlin",
    email: "balaioberlin@gmail.com",
    availability: "Not Available",
    brand:
      "Your dance studio in Berlin Mitte",
  };

  const location = useLocation();

  return (
    <>
      {
        <>
          <Header />
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Landing name={personalDetails.name} tagline={personalDetails.tagline} />} />
            <Route path="/forrowithmatheus" element={<ForroWithMatheus />} />
            <Route path="/portfolio" element={<Portfolio />} />

            <Route
              path="/contact"
              element={
                <Contact
                  name={personalDetails.name}
                  location={personalDetails.location}
                  email={personalDetails.email}
                />
              }
            />
            <Route path="/page-not-found" element={<PageNotFound />} />
            <Route path="*" element={<Navigate to="/page-not-found" />} />
          </Routes>
          <Footer />
        </>
      }
    </>
  );
}

export default App;
